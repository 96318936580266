<template>
  <div class="home">
    <v-layout row wrap class="d-flex justify-center">
      <v-flex xs12 md12 lg12 class="mb-5">
        <h1 class="f-alfa primary--text">
          <router-link to="/">Dashboard</router-link> / Add Agent
        </h1>
      </v-flex>
      <v-flex xs12>
        <v-layout row wrap>
          <v-flex xs12 md6 lg6 class="pa-3">
            <p class="mb-1 uppercase primary--text">Type</p>
            <v-select
              label="Select Type"
              single-line
              outlined
              v-model="type"
              :items="types"
            ></v-select>
          </v-flex>
          <v-flex xs12 md6 lg6 class="pa-3">
            <p class="mb-1 uppercase primary--text">Reference ID</p>
            <v-text-field
              disabled
              label="Reference ID"
              single-line
              outlined
              v-model="ref_id"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md6 lg6 class="pa-3">
            <p class="mb-1 uppercase primary--text">Name</p>
            <v-text-field
              label="Full Name"
              single-line
              outlined
              v-model="name"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md6 lg6 class="pa-3">
            <p class="mb-1 uppercase primary--text">Email</p>
            <v-text-field
              label="Email"
              single-line
              outlined
              v-model="email"
            ></v-text-field>
          </v-flex>
          <v-flex xs2 class="pa-3">
            <p class="mb-1 uppercase primary--text">Code</p>
            <v-select
              label="Code"
              single-line
              outlined
              v-model="code"
              :items="countryCodes"
            ></v-select>
          </v-flex>
          <v-flex xs12 md5 lg5 class="pa-3">
            <p class="mb-1 uppercase primary--text">Phone</p>

            <v-text-field
              label="Phone"
              single-line
              outlined
              v-model="phone"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md5 lg5 class="pa-3">
            <p class="mb-1 uppercase primary--text">Alt. Phone</p>
            <v-text-field
              label="Phone"
              single-line
              outlined
              v-model="phone_alt"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md6 lg6 class="pa-3">
            <p class="mb-1 uppercase primary--text">Fax</p>
            <v-text-field
              label="Fax"
              single-line
              outlined
              v-model="fax"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md6 lg6 class="pa-3">
            <p class="mb-1 uppercase primary--text">Website</p>
            <v-text-field
              label="Website"
              single-line
              outlined
              v-model="website"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md12 class="pa-3">
            <p class="mb-1 uppercase primary--text">Registration Address</p>
            <v-textarea
              label="Registration Address"
              single-line
              outlined
              v-model="address"
            ></v-textarea>
          </v-flex>
          <v-flex xs12 md6 lg6 class="pa-3">
            <p class="mb-1 uppercase primary--text">City</p>
            <v-text-field
              label="City"
              single-line
              outlined
              v-model="city"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md6 lg6 class="pa-3">
            <p class="mb-1 uppercase primary--text">Country</p>
            <v-select
              label="Country"
              single-line
              item-text="country"
              outlined
              :items="countryList"
              v-model="country"
            ></v-select>
          </v-flex>
          <v-flex
            xs12
            md6
            lg6
            class="pa-3"
            v-if="country == 'United Arab Emirates'"
          >
            <p class="mb-1 uppercase primary--text">Emirate</p>
            <v-select
              label="Emirate"
              single-line
              outlined
              :items="emirates"
              v-model="emirate"
            ></v-select>
          </v-flex>
          <v-flex xs12 class="pa-3">
            <v-divider class="mt-5 mb-4"></v-divider>
          </v-flex>

          <v-flex xs12 md6 lg6 class="pa-3">
            <p class="mb-1 uppercase primary--text">VAT</p>
            <v-text-field
              label="VAT"
              single-line
              outlined
              v-model="vat"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md6 lg6 class="pa-3">
            <p class="mb-1 uppercase primary--text">TRN No</p>
            <v-text-field
              label="TRN No"
              single-line
              outlined
              v-model="trn"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 class="pa-3">
            <v-divider class="mt-5 mb-4"></v-divider>
          </v-flex>
          <v-flex xs12 md4 class="pa-3">
            <p class="mb-1 uppercase primary--text">Commission (%)</p>
            <v-text-field
              label="Commission"
              single-line
              outlined
              v-model="commission"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md4 class="pa-3">
            <p class="mb-1 uppercase primary--text">Credit Limit</p>
            <v-text-field
              label="Credit Limit"
              single-line
              outlined
              v-model="credit"
              suffix="AED"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md4 class="pa-3">
            <p class="mb-1 uppercase primary--text">Payment Interval</p>
            <v-text-field
              label="Payment Interval"
              single-line
              outlined
              v-model="payment_interval"
              suffix="Days"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 class="pa-3">
            <v-divider class="mt-5 mb-4"></v-divider>
          </v-flex>
          <v-flex xs12 md12 class="pa-3">
            <p class="mb-1 uppercase primary--text">Copy of Trade License</p>
            <v-file-input
              single-line
              outlined
              v-model="selectedFile"
              label="Choose a file"
              @change="updateFileName"
            ></v-file-input>
          </v-flex>
          <v-flex xs4 class="pa-3 mt-5">
            <v-btn
              large
              block
              class="primary black--text"
              @click="createAgentAccount"
              >Submit</v-btn
            >
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>
  
  <script>
import { BASE_URL } from "@/config";
import { countries } from "@/country-codes";
import Axios from "axios";

export default {
  data() {
    return {
      type: "Individual",
      types: ["Individual", "Organisation"],
      ref_id: "123",
      name: "",
      code: "+971",
      phone: "",
      phone_alt: "",
      fax: "",
      email: "",
      address: "",
      city: "",
      country: "United Arab Emirates",
      emirate: "",
      website: "",
      vat: "",
      trn: "",
      commission: "",
      credit: "",
      payment_interval: "14",
      emirates: [
        "Abu Dhabi",
        "Ajman",
        "Dubai",
        "Fujairah",
        "Ras Al Khaimah",
        "Sharjah",
        "UAQ",
      ],
    };
  },
  methods: {
    async createAgentAccount() {
      let url = BASE_URL + "/agent";
      let payload = {
        type: this.type,
        ref_id: this.ref_id,
        name: this.name,
        email: this.email,
        code: this.code,
        phone: this.phone,
        phone_alt: this.phone_alt,
        fax: this.fax,
        website: this.website,
        address: this.address,
        city: this.city,
        country: this.country,
        emirate: this.emirate,
        vat: this.vat,
        trn: this.trn,
        commission: this.commission,
        payment_interval: this.payment_interval,
        credit: this.credit,
      };
      
      let {data} =  await Axios.post(url, payload);
      this.type = "Individual"
      this.ref_id = ""  
      this.name = ""
      this.email = ""
      this.code = "+971"
      this.phone = ""
      this.phone_alt = ""
      this.fax = ""
      this.website = ""
      this.address = ""
      this.city = ""
      this.country = "United Arab Emirates"
      this.emirate = ""
      this.vat = ""
      this.trn = ""
      this.commission = ""
      this.payment_interval = "14"
      this.credit = ""
    },
  },
  computed: {
    countryList() {
      return countries;
    },
    countryCodes() {
      return this.countryList.map((country) => country.code).sort();
    },
  },
};
</script>

<style>
.v-text-field__suffix {
  color: #ba9d73 !important;
}
</style>